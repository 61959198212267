import { CompanyPreferencesModel } from './company-preferences.model';
import { UserPermissionsModel } from './user-permissions.model';
import { UserPreferencesModel } from './user-preferences.model';
import { PrimaryContactModel } from './primary-contact.model';
import { BaseModel } from './base.model';

export class UserModel extends BaseModel {
  public companyId: number;
  public companyPreferences: CompanyPreferencesModel;
  public email: string;
  public firstName: string;
  public id: number;
  public lastName: string;
  public middleName: string;
  public phone: string;
  public primaryContact: PrimaryContactModel;
  public siteId: number;
  public status: string;
  public teamIds: number[];
  public userName: string;
  public userPermissions: UserPermissionsModel;
  public userPreferences: UserPreferencesModel;

  constructor(data: any = {}) {
    super();
    this.companyId = data.companyId;
    this.companyPreferences = CompanyPreferencesModel.transform(
      data.companyPreferences
    );
    this.email = data.email;
    this.firstName = data.firstName;
    this.id = data.id;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.phone = data.phone;
    this.primaryContact = PrimaryContactModel.transform(data.primaryContact);
    this.siteId = data.siteId;
    this.status = data.status;
    this.teamIds = data.teamIds;
    this.userName = data.userName;
    this.userPermissions = UserPermissionsModel.transform(data.userPermissions);
    this.userPreferences = UserPreferencesModel.transform(data.userPreferences);
  }

  public static override transform(data: any): UserModel {
    return new UserModel(data);
  }
}
