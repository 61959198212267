import { BaseModel } from './base.model';

export class UserPermissionsModel extends BaseModel {
  public functionPoints: string[];
  public functionRules: string[];

  constructor(data: any = {}) {
    super();
    this.functionPoints = data.functionPoints;
    this.functionRules = data.functionRules;
  }

  public static override transform(data: any): UserPermissionsModel {
    return new UserPermissionsModel(data);
  }
}
