import { BaseModel } from './base.model';

export class CompanyPreferencesModel extends BaseModel {
  public customAccountLink: string;
  public customAccountLinkText: string;
  public customContactLink: string;
  public customContactLinkText: string;
  public enableiPlannerIntegration: boolean;
  public hasAutoUpdateEnabled: boolean;
  public useCustomFxRates: boolean;

  constructor(data: any = {}) {
    super();
    this.customAccountLink = data.customAccountLink;
    this.customAccountLinkText = data.customAccountLinkText;
    this.customContactLink = data.customContactLink;
    this.customContactLinkText = data.customContactLinkText;
    this.enableiPlannerIntegration = data.enableiPlannerIntegration;
    this.hasAutoUpdateEnabled = data.hasAutoUpdateEnabled;
    this.useCustomFxRates = data.useCustomFxRates;
  }

  public static override transform(data: any): CompanyPreferencesModel {
    return new CompanyPreferencesModel(data);
  }
}
