import { BaseModel } from './base.model';

export class TimeZoneModel extends BaseModel {
  public timezone_cd: string;
  public timezone_id: number;
  public timezone_nm: string;
  public timezone_offset: number;

  constructor(data: any = {}) {
    super();
    this.timezone_cd = data.timezone_cd;
    this.timezone_id = data.timezone_id;
    this.timezone_nm = data.timezone_nm;
    this.timezone_offset = data.timezone_offset;
  }

  public static override transform(data: any): TimeZoneModel {
    return new TimeZoneModel(data);
  }
}
