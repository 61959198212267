import { AlertNotificationService } from '../helpers/alert-notification/alert-notification.service';
import { environment } from '../../../../environments/environment';
import { HttpHeadersService } from './http-headers.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestHandlerService {
  constructor(
    private httpHeadersService: HttpHeadersService,
    private alertNotificationService: AlertNotificationService
  ) {}

  public errorHandler(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    if (error.status !== 401) {
      this.alertNotificationService.alert(
        'error',
        `${error.status} - ${error.name}`
      );
    }

    return error.status === 401
      ? this.removeAuth(error)
      : this.onError(error.error || error);
  }

  public removeAuth(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.httpHeadersService.removeAuth();
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    location.href = `${environment.baseUrl}/login?r=${
      environment.name === 'development' ? environment.webUrl : location.href
    }`;
    return this.onError(error.error || error);
  }

  public onError(error: any): Observable<HttpErrorResponse> {
    return throwError(error);
  }
}
