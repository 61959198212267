import { BaseService } from '../../http-client/base.service';
import { UserModel } from '../../../models/user.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  public authUser!: UserModel;

  public setAuthUser(user: UserModel): void {
    localStorage.setItem('user', JSON.stringify(user));

    this.authUser = user;
  }

  public setAuthUserAccessToken(token: string): void {
    localStorage.setItem('access_token', token);
    this.httpHeadersService.setAuth(token);
  }

  public getAuthUser(): UserModel {
    const str = localStorage.getItem('user');

    return str ? new UserModel(JSON.parse(str)) : new UserModel({});
  }
}
