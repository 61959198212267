import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertNotificationService {
  private configs = {
    easing: 'ease-in',
    timeOut: 5000,
    onActivateTick: true,
    positionClass: 'toast-top-center',
    progressBar: true,
    closeButton: true,
  };

  constructor(private toastr: ToastrService) {}

  public alert(type: string, message: string, title?: string): void {
    switch (type) {
      case 'success':
        this.toastr.success(message, title, this.configs);
        break;
      case 'info':
        this.toastr.info(message, title, this.configs);
        break;
      case 'warning':
        this.toastr.warning(message, title, this.configs);
        break;
      case 'error':
        this.toastr.error(message, title, this.configs);
        break;
    }
  }
}
