import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../services/api/v1/user.service';
import { AuthService } from '../services/api/v1/auth.service';
import { catchError, map } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    environment.name === 'development' && this.setAccessToken(route);
    console.log('environment - ', environment.name);

    return this.userService.getCurrentUser().pipe(
      map((user) => this.onSuccess(user)),
      catchError(() => this.onError())
    );
  }

  private onSuccess(user: UserModel): boolean {
    this.authService.setAuthUser(user);
    return true;
  }

  private onError(): Observable<boolean> {
    // location.href = `${environment.baseUrl}/login?r=${environment.webUrl}`;
    location.href = `${environment.baseUrl}/login?r=${
      environment.name === 'development' ? environment.webUrl : location.href
    }`;
    return of(false);
  }

  private setAccessToken(route: ActivatedRouteSnapshot): void {
    const storageToken = localStorage.getItem('access_token');
    const urlToken = route.queryParams['access_token'];

    if (urlToken) {
      this.authService.setAuthUserAccessToken(urlToken);
      this.router.navigate(['/']);
      return;
    }

    this.authService.setAuthUserAccessToken(storageToken || '');
  }
}
