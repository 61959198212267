import { TimeZoneModel } from './time-zone.model';
import { BaseModel } from './base.model';

export class UserPreferencesModel extends BaseModel {
  public culture: string;
  public currency: string;
  public currencyRate: number;
  public language: string;
  public shortDatePattern: string;
  public shortTimePattern: string;
  public showCurrencySymbol: boolean;
  public dateTimePattern: string;
  public dstTimeZone: TimeZoneModel;

  constructor(data: any = {}) {
    super();
    this.culture = data.culture;
    this.currency = data.currency;
    this.currencyRate = data.currencyRate;
    this.language = data.language;
    this.shortDatePattern = data.shortDatePattern?.toUpperCase();
    this.shortTimePattern = data.shortTimePattern;
    this.dateTimePattern = this.formattedDateTime();
    this.showCurrencySymbol = data.showCurrencySymbol;
    this.dstTimeZone = TimeZoneModel.transform(data.dstTimeZone);
  }

  public static override transform(data: any): UserPreferencesModel {
    return new UserPreferencesModel(data);
  }

  private formattedDateTime(): string {
    const timePatternParts = this.shortTimePattern?.split(' ');
    const index = timePatternParts.findIndex((part: string) => part === 'tt');
    if (index > -1) {
      timePatternParts.splice(index, 1, 'a');
      return `${this.shortDatePattern} ${timePatternParts.join(' ')}`;
    }
    return `${this.shortDatePattern} ${timePatternParts.join(' ')}`;
  }
}
